<template>
  <div class="pa-2">
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12">Voucher List</v-col>
          <v-col cols="2" class="pa-1 px-2">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              v-model="toMeun"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fromDateValue"
                  label="From Date"
                  prepend-icon="event"
                  hide-details
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker :type="datePickerType" v-model="fromDate" @input="toMeun = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" class="pa-1 px-2">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              v-model="fromMeun"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="toDateValue"
                  label="To Date"
                  prepend-icon="event"
                  hide-details
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker :type="datePickerType" v-model="toDate" @input="fromMeun = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="1" class="text-left pa-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" depressed v-on="on" @click="searchVoucherList" small fab>
                  <v-icon>search</v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" offset="1">
            <v-text-field
              placeholder="PNR ID"
              name="voucherId"
              label="PNR ID"
              v-model="pnr"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-left pa-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" @click="getVoucherByPnr" depressed v-on="on" small fab>
                  <v-icon>search</v-icon>
                </v-btn>
              </template>
              <span>Search By PNR</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-text-field
              placeholder="Voucher ID"
              v-model="voucherId"
              label="Voucher ID"
              name="voucherId"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-left pa-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  @click="getVoucherByVocucherCode"
                  depressed
                  v-on="on"
                  small
                  fab
                >
                  <v-icon>search</v-icon>
                </v-btn>
              </template>
              <span>Search By ID</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-simple-table fixed-header height="72vh">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th rowspan="2" class="text-center">Voucher Code</th>
                    <th rowspan="2" class="text-center">Voucher Date</th>
                    <th rowspan="2" class="text-center">Modified Date</th>
                    <th rowspan="2" class="text-center">To</th>
                    <th rowspan="2" class="text-center">For</th>
                    <th rowspan="2" class="text-center">Issused By</th>
                    <th colspan="2" class="text-center">Total Amount</th>
                    <th rowspan="2" class="text-center">PNR</th>
                    <th rowspan="2" class="text-center">Action</th>
                  </tr>
                  <tr>
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in voucherList" style="height: 10px !important;" :key="item.name">
                    <td class="text-center" style="width:40px;">{{ item.voucherCode }}</td>
                    <td class="text-center" style="width:40px;">{{ item.date | formatDate }}</td>
                    <td class="text-center" style="width:40px;">{{ item.modifiedDate | formatDate }}</td>
                    <td class="text-left" style="width:100px;">{{ item.toCustomer }}</td>
                    <td class="text-left">{{ item.forPerson }}</td>
                    <td class="text-center" style="width:80px;">{{ item.issuedBy }}</td>
                    <td
                      class="text-right"
                      style="width:80px;"
                    >{{ item.mmkAmount | defaultPaymentFormat }}</td>
                    <td
                      class="text-right"
                      style="width:70px;"
                    >{{ item.usdAmount | defaultPaymentFormat }}</td>
                    <td class="text-right" style="width:50px;">{{ item.pnr }}</td>
                    <td class="text-right" style="width:250px;">
                      <v-tooltip v-if="item.cashback != false" bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="printVoucherCashBack(item)"
                            color="primary"
                            class="mr-1"
                            depressed
                            v-on="on"
                            small
                            icon
                          >
                            <v-icon>unarchive</v-icon>
                          </v-btn>
                        </template>
                        <span>Cashback</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="printVoucher(item,-1)"
                            color="primary"
                            class="mr-1"
                            depressed
                            v-on="on"
                            small
                            icon
                          >
                            <v-icon>print</v-icon>
                          </v-btn>
                        </template>
                        <span>Print</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            color="primary"
                            @click="showVoucherHistory(item)"
                            depressed
                            v-on="on"
                            small
                            icon
                          >
                            <v-icon>history</v-icon>
                          </v-btn>
                        </template>
                        <span>History</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="uiAccept.vlchange">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            color="primary"
                            depressed
                            v-on="on"
                            small
                            icon
                            @click="changes(item)"
                          >
                            <v-icon>event</v-icon>
                          </v-btn>
                        </template>
                        <span>Change</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="uiAccept.vlrefund">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="refundVoucher(item)"
                            color="primary"
                            class="mr-1"
                            depressed
                            v-on="on"
                            small
                            icon
                          >
                            <v-icon>strikethrough_s</v-icon>
                          </v-btn>
                        </template>
                        <span>Refund</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="uiAccept.vledit">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="editVoucher(item)"
                            color="warning"
                            class="mr-1"
                            depressed
                            v-on="on"
                            small
                            icon
                          >
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip v-if="uiAccept.vldelete" bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="deleteVoucher(item)"
                            color="error"
                            class="mr-1"
                            depressed
                            v-on="on"
                            small
                            icon
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="userRole=='ROLE_ADMIN' || userRole=='ROLE_ACCOUNTANT'"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="changesLog(item)"
                            color="warning"
                            class="mr-1"
                            depressed
                            v-on="on"
                            small
                            icon
                          >
                            <v-icon>change_history</v-icon>
                          </v-btn>
                        </template>
                        <span>Log</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="voucherDetaildialog" max-width="550">
      <v-card>
        <v-card-title class="headline">Voucher History</v-card-title>
        <v-card-text class="pa-2 pt-0">
          <v-expansion-panels v-model="panel" multiple focusable>
            <v-expansion-panel
              v-for="(changedVoucher,i) in voucherHistory.historyDetailList"
              :key="i"
            >
              <v-expansion-panel-header>
                <v-row no-gutters>
                  <v-col cols="6" class="pt-2">{{ changedVoucher.title }}</v-col>
                  <v-col cols="3" class="pt-2 text-center">{{ changedVoucher.dateTitle }}</v-col>
                  <v-col cols="1">
                    <v-btn
                      v-if="changedVoucher.title.indexOf('AC') != -1 || changedVoucher.title.indexOf('AR') != -1"
                      @click="deleteHistoryVoucher(voucherHistory,changedVoucher)"
                      color="red"
                      depressed
                      small
                      icon
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      v-if="changedVoucher.title.indexOf('AC') != -1 || changedVoucher.title.indexOf('AR') != -1"
                      @click="editHistoryVoucher(voucherHistory,changedVoucher)"
                      color="warning"
                      depressed
                      small
                      icon
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      @click="printVoucher(voucherHistory,changedVoucher)"
                      color="primary"
                      depressed
                      small
                      icon
                    >
                      <v-icon>print</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row v-for="statement in changedVoucher.historyList" :key="statement.id">
                  <v-col cols="6">{{statement.name}}</v-col>
                  <v-col cols="6">{{statement.data}}</v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changesDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Changes</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-btn block height="180" class="body-1 text-center" @click="dateSectorChange">
                Date and
                <br />Sector Change
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block height="180" class="body-1 text-center" @click="nameChange">Name Change</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="logDialog" max-width="500" scrollable>
      <v-card>
        <v-card-title>Log</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-html="logData"></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDeleteVoucher"
      width="500"
    >
      <v-card>
        <v-card-title class="red white--text">
          Are you sure to delete ({{tempDelete.code}})?
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="remark" name="remarkremark" label="Remark" dense class="ml-2 mr-2 mt-4"></v-text-field>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogDeleteVoucher = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red"
            text
            @click="clickDeleteHistory()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <refund-dialog :dialog.sync="refundDialog" :refund="refund" :voucher="voucher" />
  </div>
</template>
<script>
import RefundDialog from "../components/RefundDialog";
import voucherService from "../service/VoucherService";

export default {
  data() {
    return {
      panel: [],
      pnr: null,
      voucherHistory: {},
      voucherDetaildialog: false,
      voucherId: null,
      toDate: new Date().toISOString().substr(0, 10),
      fromDate: new Date().toISOString().substr(0, 10),
      toMeun: false,
      fromMeun: false,
      dateType: "date",
      datePickerType: "date",
      voucherList: [],
      changesDialog: false,
      selectItemId: 0,
      userRole: "",
      logDialog: false,
      logData: "",
      voucher: null,
      refund: {
        refundCode: String,
        remark: String,
        usdTotal: Number,
        mmkTotal: Number,
        moneyType: String, //mmk,usd
        refundWay: []
      },
      refundDialog: false,
      dialogDeleteVoucher:false,
      tempDelete:{},
      tempHistory:{},
      remark:"",
      uiAccept:{},
    };
  },
  mounted: function() {
    this.uiAccept = this.$store.state.uiAccept;
    // let i = 0;
    if (this.$route.query.fromDate != undefined) {
      this.fromDateValue = this.formatDate(this.$route.query.fromDate);
      // i++;
    }
    if (this.$route.query.toDate != undefined) {
      this.toDateValue = this.formatDate(this.$route.query.toDate);
      // i++;
    }
    // if (i == 2) {
    //   }
    this.searchVoucherList();
    this.userRole = this.$store.state.userAccount.role;
  },
  methods: {
    refundVoucher: function(voucher) {
      this.$router
        .push({
          name: "refund",
          params: {
            voucherId: voucher.voucherId
          }
        })
        .catch(() => {});
    },
    printVoucherCashBack: function(voucher) {
      this.$router
        .push({
          name: "voucherCashbackPrint",
          query: {
            voucherId: voucher.voucherId
          }
        })
        .catch(() => {});
    },
    clickDeleteHistory:function(){
      this.dialogDeleteVoucher = false;
      voucherService.deleteVouchersCode(this.tempDelete.code,this.tempHistory.voucherId,this.remark).then(response => {
              this.searchVoucherList();
              this.voucherDetaildialog = false;
            });
    },
    deleteHistoryVoucher: function(voucherHistory, changedVoucher) {
      this.dialogDeleteVoucher = true;
      this.tempDelete = changedVoucher;
      this.tempHistory = voucherHistory;
      
    },
    editHistoryVoucher: function(voucherHistory, changedVoucher) {
      if (changedVoucher.title.indexOf("Name") != -1) {
        this.$router.push({
          name: "nameChangeUpdate",
          params: {
            voucherId: voucherHistory.voucherId,
            changesId: changedVoucher.id
          }
        });
      } else if (changedVoucher.title.indexOf("AR") != -1) {
        voucherService.getVoucherId(this.voucher.voucherId).then(data => {
          this.voucher = data;
          voucherService
            .getRefundById(this.voucher.voucherId, changedVoucher.id)
            .then(data => {
              this.refund = data;
              this.refundDialog = true;
            });
        });

        //
      } else {
        this.$router.push({
          name: "dateSectorChange",
          params: {
            voucherId: voucherHistory.voucherId
          },
          query: {
            codeId: changedVoucher.code
          }
        });
      }
    },
    printVoucher: function(voucher, code = 0) {
      if (code == -1) {
        this.$router
          .push({
            name: "voucherPrint",
            query: {
              voucherId: voucher.voucherId,
              code: 0
            }
          })
          .catch(() => {});
      } else {
        var codeTitle = code.title;
        var codeId = code.id;
        var res = codeTitle.split("(", 6);
        var voucherCodeVar = res[0];
        if (voucherCodeVar.indexOf("AC") != -1) {
          this.$router
            .push({
              name: "voucherPrintChange",
              query: {
                voucherId: voucher.voucherId,
                code: code.code
              }
            })
            .catch(() => {});
        } else if (voucherCodeVar.indexOf("AI") != -1) {
          this.$router
            .push({
              name: "voucherPrint",
              query: {
                voucherId: voucher.voucherId,
                code: 0
              }
            })
            .catch(() => {});
        } else {
          this.$router
            .push({
              name: "voucherExpensePrint",
              query: {
                voucherId: voucher.voucherId,
                refundId: codeId
              }
            })
            .catch(() => {});
        }
      }
    },
    editVoucher: function(vocuher) {
      this.$router
        .push({
          name: "voucher",
          params: {
            voucherId: vocuher.voucherId
          }
        })
        .catch(() => {});
    },
    showVoucherHistory: function(voucher) {
      this.voucher = voucher;
      voucherService.getVoucherHistory(voucher.voucherId).then(response => {
        this.voucherHistory = response;
        this.panel = [];
        // this.panel = [...Array(response.historyDetailList).keys()].map((k, i) => i);
        this.voucherDetaildialog = true;
      });
    },
    deleteVoucher: function(voucher) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Data!",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          voucherService
            .deleteVoucher(voucher.voucherId)
            .then(response => {
              if (response == "") {
                this.$swal("Successful", "Delete Successful!", "success");
                this.searchVoucherList();
              }
            })
            .catch(() => {
              this.$swal("unSuccessful", "Something were wrong.", "error");
            });
        }
      });
    },
    getVoucherByVocucherCode: function() {
      voucherService.getVoucherByVocucherCode(this.voucherId).then(response => {
        this.voucherList = response;
      });
    },
    getVoucherByPnr: function() {
      voucherService.getVoucherByPnr(this.pnr).then(response => {
        this.voucherList = response;
      });
    },
    searchHistory: function(fromDate, toDate) {
      let query = {
        fromDate: fromDate,
        toDate: toDate
      };
      this.$router
        .replace({
          path: "/voucherList",
          query
        })
        .catch(() => {});
    },
    searchVoucherList: function() {
      voucherService
        .getVoucherList(
          this.formatDateUrl(this.fromDateValue),
          this.formatDateUrl(this.toDateValue)
        )
        .then(response => {
          this.searchHistory(this.fromDateValue, this.toDateValue);
          //this.voucherList = response;
          this.voucherList = this.voucherList.filter(() => false);
          this.voucherList.push(...response);
        });
    },
    formatDateUrl: function(date) {
      //if (!date) return null;
      if (this.dateType == "month") {
        const [month, year] = date.split("-");
        return `01-${month}-${year}`;
      } else if (this.dateType == "year") {
        const [year] = date.split("-");
        return `01-01-${year}`;
      }
      return date;
    },
    formatDate: function(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      if (this.dateType == "date") {
        // const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      } else if (this.dateType == "month") {
        // const [year, month, day] = date.split("-");
        return `${month}-${year}`;
      } else {
        // const [year, month, day] = date.split("-");
        return `${year}`;
      }
    },
    changes: function(item) {
      this.changesDialog = true;
      this.selectItemId = item.voucherId;
    },
    dateSectorChange: function() {
      this.selectItemId;
      this.$router
        .push({
          name: "dateSectorChange",
          params: {
            voucherId: this.selectItemId
          }
        })
        .catch(() => {});
    },
    nameChange: function() {
      this.$router
        .push({
          name: "nameChange",
          params: {
            voucherId: this.selectItemId
          }
        })
        .catch(() => {});
    },
    changesLog: function(item) {
      voucherService
        .getVoucherLogById(item.voucherId)
        .then(data => {
          this.logData = data;
          this.logDialog = true;
        })
        .catch(() => {
          this.logData = "No Record";
          this.logDialog = true;
        });
    }
  },
  computed: {
    toDateValue: {
      get: function() {
        var toDatetemp = "";
        toDatetemp = this.formatDate(this.toDate);
        return toDatetemp;
      },
      set: function(value) {
        this.toDate = value;
      }
    },
    fromDateValue: {
      get: function() {
        var fromDatetemp = "";
        fromDatetemp = this.formatDate(this.fromDate);
        return fromDatetemp;
      },
      set: function(value) {
        this.fromDate = value;
      }
    }
  },
  components: {
    "refund-dialog": RefundDialog
  }
};
</script>
<style scoped>
.v-expansion-panel-header {
  padding: 8px 10px;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #ffffff;
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
tr td {
  padding: 0px 3px 0px 3px !important;
  font-size: 12px;
}
tr th {
  font-size: 12px;
  padding: 0px 2px 0px 2px !important;
  font-weight: 900;
}
</style>